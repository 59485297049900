require('./bootstrap');


/*window.$ = require('jquery');*/
import $ from 'jquery';
window.$ = window.jQuery = $;
// jQuery datepicker
import 'jquery-ui/ui/widgets/datepicker.js';
// International Telephone Input
import 'intl-tel-input/build/js/intlTelInput-jquery.min.js'
import 'intl-tel-input/build/js/utils.js'
import 'datatables.net/js/jquery.dataTables.js';
import 'datatables.net-responsive/js/dataTables.responsive.min.js';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js';
require('select2');
// jquery numeric
require('jquery.numeric/jquery.numeric.min');
import 'iban/iban.js';
window.IBAN = require('iban');

//import intlTelInput from 'intl-tel-input';


